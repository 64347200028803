import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getOrder } from '../services/getOrder'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { defaultCP } from '../utils/defaultCP'
import OrderAccordian from '../components/OrderAccordian'
import { useTranslation } from 'react-i18next'
import OrderCampaignHeader from '../components/OrderCampaignHeader'
import OrderSideBar from '../components/OrderSideBar'
import { FaArrowLeft } from 'react-icons/fa'
import Select from '../components/Select'
import { handleAutoApprove } from '../services/handleAutoApprove'
import FeedbackPopup from '../components/FeedbackPopup'

function CustomerOrder() {

    const { id } = useParams()
    const [data, setData] = useState(null)
    const [orders, setOrders] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [t] = useTranslation("global")
    const navigate = useNavigate()
    const [sort, setSort] = useState('All')

    useEffect(() => {
        setOrders([])
        if (sort === 'All') {
            setOrders(data?.orders || [])
        }
        else {
            setOrders(data?.orders.filter(order => order.status === sort) || [])
        }
    }, [sort])

    useEffect(() => {
        const fetchData = async () => {
            const data = await getOrder(id)
            setOrders(data?.orders || [])
            // setData({...data, campaign : {
            //     ...data.campaign,
            //     status: 'Recruitment Period'
            // }})
            setData(data)
        }
        fetchData()
    }, [refresh])

    return (

        <>
            {data && <div className='py-10 flex justify-between items-start sm:flex-col sm:gap-5 gap-16 sm:py-5 sm:pb-32 sm:px-6'>

                <div className='w-[70%] flex flex-col gap-5  sm:w-full'>
                    <div className='hidden sm:flex justify-start items-center gap-5'>
                        <FaArrowLeft onClick={() => navigate(-1)} className='bg-themePink text-[28px] px-2 py-1 text-white rounded-full' />
                        <h1 className='text-black text-lg font-bold'>{t('order')}</h1>
                    </div>
                    <OrderCampaignHeader campaign={data?.campaign} />
                    <div className='flex justify-end sm:justify-start items-center -mb-2 gap-3 sm:gap-2 sm:flex-wrap'>
                        {(data.campaign?.status === 'Recruitment Period' || data.campaign?.status === 'Approve Period')
                         && <p
                            onClick={() => {
                                handleAutoApprove(data?.campaign._id)
                                setRefresh(!refresh)
                            }}
                            className='flex sm:flex-grow sm:min-w-[48%] justify-center px-4 py-2.5 whitespace-nowrap text-sm border-[1px] shadow-sm cursor-pointer border-themeGrey-70 rounded-lg'>
                            {data.campaign.auto_approve ? t('disableAutoApprove') : t('autoapprove')}
                        </p>}
                        {orders.filter(o => !o.review_given)?.length > 0 && 
                        <span className='sm:min-w-[45%] flex-grow'>
                            <FeedbackPopup campaign={data.campaign} orders={data.orders} setRefresh={setRefresh}/>
                        </span>
                        }
                        <span className='w-1/4 sm:w-full'>
                            <Select
                                options={
                                    [
                                        { label: 'All', value: 'All' },
                                        { label: 'Pending', value: 'Pending' },
                                        { label: 'Active', value: 'Active' },
                                        { label: 'Delivered', value: 'Delivered' },
                                        { label: 'Completed', value: 'Completed' },
                                        { label: 'Disputed', value: 'Disputed' },
                                        { label: 'Cancelled', value: 'Cancelled' }
                                    ]
                                }
                                onChange={(e) => setSort(e.target.value)}
                                value={sort}
                                simple={true}
                            />
                        </span>
                    </div>

                    {orders && <div className='flex flex-col gap-2'>
                        <div className='flex flex-col gap-4'>
                            {orders.length === 0 && <p className='text-xs text-[#555555]'>{t("no_activity")}</p>}
                            {orders?.map((order, index) => (
                                <OrderAccordian order={order} key={index} setRefresh={setRefresh} index={index} />
                            ))}
                        </div>
                    </div>}

                </div>


                <div className='w-[30%] sm:w-full'>
                    <OrderSideBar campaign={data.campaign} orders={data.orders} setRefresh={setRefresh} />
                </div>
            </div>}
        </>
    )
}

export default CustomerOrder
