import React, { useContext, useEffect, useState } from 'react';
import Guide from '../Guide';
import Select from '../Select';
import { useTranslation } from 'react-i18next';
import { FaEquals } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';
import { getLevels } from '../../services/getLevels';
import Tab from '../Tab';
import { BASE_URL, CHANNEL_KEY, MERCHANT_ID, PLATFORMS, STORE_ID } from '../../constants';
import Button from '../Button';
import { createUpdateCampaign } from '../../services/createUpdateCampaign';
import { useNavigate } from 'react-router-dom';
import { Context } from '../..//Provider/LangaugeProvider'


function Media({ campaignData, setActive }) {
    const [platforms, setPlatforms] = useState(PLATFORMS);
    const { sitelang } = useContext(Context)
    const [t] = useTranslation("global");
    const [selectedMedia, setSelectedMedia] = useState(
        campaignData.campaign_option === '10%' ?
            platforms.filter((p) => p.key === 'instagram')[0]
            :
            campaignData.platform ? platforms.filter((p) => p.key === campaignData.platform)[0]
                :
                platforms[0]
    );
    const [levels, setLevels] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeBtn, setActiveBtn] = useState(false);
    const navigate = useNavigate()
    const [levelMediaChanged, setLevelMediaChanged] = useState(false);
    const [total, setTotal] = useState({
        total: 0,
        vat: 0,
        grandTotal: 0
    });
    const [amounts, setAmounts] = useState({
        media_amount: 0,
        delivery_amount: 0,
        tenPercent: 0,
        review_amount:0
    });
    const [data, setData] = useState({
        ...campaignData,
        creator_count: campaignData.creator_count ? campaignData.creator_count : 1,
        region: campaignData.region ? campaignData.region: 'Korea' , 
        creatorLevel: campaignData.campaign_option === '10%' ? 'All' : 'Level 1',
        platform: campaignData.campaign_option === '10%' ? 'instagram' : campaignData.platform,
    });


    useEffect(() => {
        const fetchLevels = async () => {
            const levels = await getLevels(false);
            let options = levels.map(l => ({ ...l, value: l.name, label: l.name }));
            const level1 = options.find(l => l.value === 'Entry');
            if (campaignData.creatorLevel) {
                setData({ ...data, creatorLevel: campaignData.creatorLevel });
                setSelectedLevel(options.find(l => l.value === campaignData.creatorLevel));
            }
            else {
                setData({ ...data, creatorLevel: level1.value });
                setSelectedLevel(level1);
            }
            setLevels(options);
        };
        fetchLevels();
    }, []);


    useEffect(() => {
        let amount = {
            media_amount: selectedLevel?.[`${selectedMedia?.key?.toLocaleLowerCase()}_amount`] || 0,
            delivery_amount: selectedLevel?.delivery_amount || 0,
            tenPercent: selectedLevel?.tenPercent || 0,
            review_amount: selectedLevel?.review_amount || 0
        }
        setAmounts(amount);
    }, [selectedMedia, selectedLevel]);

    const handleChange = (e) => {
        let value = parseInt(e.target.value);
        if ((value < 0 || isNaN(value) || value > 1000000) && e.target.name === 'creator_count') {
            value = 1;
        }
        setData(prevData => ({
            ...prevData,
            [e.target.name]: value,
        }));
    };

    useEffect(() => {
        const calaculateTotal = () => {

            let total = 0
            if (["Payback", "Review"].includes(data.campaign_option)) {
                total += Number(
                    (amounts.media_amount * data.creator_count)
                )
            }

            if (data.campaign_option === "Review" && data.campaign_type === 'Product') {
                total += Number(
                    (amounts.delivery_amount * data.creator_count)
                )
            }
            if (data.campaign_option === "Payback") {
                total += Number(
                    (data.payback_amount * data.creator_count)
                )
            }
            if (data.campaign_option === "10%") {
                total += Number(
                    (data.creator_count * amounts.tenPercent)
                )
            }
            if(data.review){
                total += Number(
                    (amounts.review_amount * data.creator_count)
                )
            }
            let vat = Number((total * 0.1).toFixed(2));
            let grandTotal = Number(total + vat);
            setTotal({ total, vat, grandTotal });
        }
        calaculateTotal()
    }, [data, levels, amounts]);

    useEffect(() => {
        if (levelMediaChanged) {
            setData(prevData => ({ ...prevData, creator_count: 1, subscription_month: 0, payback_amount: 0, review_amount: 0 }));
            setTotal({ total: 0, vat: 0, grandTotal: 0 });
            setLevelMediaChanged(false);
        }

    }, [levelMediaChanged])



    useEffect(() => {
        setActiveBtn(data.creator_count > 0 && data.region && data.platform && data.creatorLevel);
    }, [data]);



    const onClick = async () => {
        setError(null);
        setLoading(true);
        data.grand_total = Number(total.grandTotal);

        data.media_fee = ["Payback", "Review"].includes(data.campaign_option) ? amounts.media_amount : 0;

        data.payback_amount = data.campaign_option === 'Payback' ? data.payback_amount : 0;
        data.delivery_fee = (data.campaign_option === 'Review' && data.campaign_type === 'Product') ? amounts.delivery_amount : 0;
        data.tenPercent_fee = data.campaign_option === '10%' ? amounts.tenPercent : 0;
        data.review_amount = data.review ? amounts.review_amount : 0;


        const response = await createUpdateCampaign(data, "Media");
        const res = await response.json();
        if (response.status === 200) {
            setActive(3)
        }
        else {
            setError(res.message);
        }
        setLoading(false);
    }

    console.log(data?.platform || selectedMedia);
    

    return (
        <div className='flex flex-col gap-5 py-6 sm:pt-0 sm:pb-18 relative'>
            <div className='flex justify-between items-center px-6 sm:flex-col sm:justify-start sm:items-start sm:gap-3'>
                <h3 className='text-themeBlack-300 font-semibold text-lg sm:text-sm'>{t("SelectCreatorLevel")}</h3>
                <span className='w-[62%] flex gap-2 sm:w-full sm:flex-wrap'>
                    <span className='w-1/3 sm:w-full'>
                        <Select
                            options={platforms.map(p => ({ value: p.key, label: p.title, icon: p.logo }))}
                            name='platform'
                            onChange={(e) => {
                                const selected = platforms.find(p => p.key === e.target.value);
                                setData(prevData => ({ ...prevData, platform: e.target.value }));
                                setSelectedMedia(selected);
                                setLevelMediaChanged(true);
                            }}
                            value={data?.platform || selectedMedia.key}
                            readOnly={data.paymentProccessed || data.campaign_option === '10%'}
                            simple
                        />
                    </span>
                    <span className='w-1/3 sm:w-[49%]'>
                        {data.campaign_option === '10%' ?
                            <input
                                value={'ALL'}
                                readOnly={true}
                                className='w-full text-center px-4 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300 placeholder:text-left' />
                            :
                            <Select
                                options={levels}
                                name='creator_level'
                                onChange={(e) => { setData(prevData => ({ ...prevData, creatorLevel: e.target.value })); setSelectedLevel(levels.find(l => l.value === e.target.value)); setLevelMediaChanged(true) }}
                                value={selectedLevel?.label}
                                readOnly={data.paymentProccessed}
                                simple
                            />}
                    </span>
                    <span className='w-1/3 sm:w-[48%] relative'>
                        <input
                            type='number'
                            min="0"
                            name="creator_count"
                            value={data?.creator_count}
                            onChange={(e) => {
                                if (/\d/.test(e.target.value)) {
                                    handleChange(e);
                                }
                            }}
                            placeholder={t('number_of_creators')}
                            readOnly={data.paymentProccessed}
                            onKeyDown={(e) => {
                                if (e.key === '-' || e.key === 'e') e.preventDefault();
                            }}
                            className='w-full text-center px-4 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300 placeholder:text-left'
                        />
                        <p className='absolute right-7 top-2'>{sitelang !== 'eng' ? '명': 'Hc'}</p>
                    </span>
                </span>
            </div>
            {(data.campaign_option === 'Payback' || data.campaign_option === '10%') &&
                <div className='flex justify-end items-end gap-5 px-6 sm:-mt-3'>
                    <div className='w-[60%] sm:w-full flex justify-end items-end gap-2'>
                        {data.campaign_option === 'Payback' && <span className='flex flex-col gap-1 w-1/3 sm:w-1/2 mr-4'>
                            <labe className='text-themeBlack-300  text-xs '>{t('payback_amount')}</labe>
                            <input
                                type='number'
                                name='payback_amount'
                                readOnly={data.paymentProccessed}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') e.preventDefault();
                                }}
                                value={data.payback_amount}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                        </span>}
                        <div className='flex justify-start items-center gap-4 mb-3  min:w-1/3 sm:w-[48%] '>
                            <input type='checkbox' className='checkbox' onChange={() => setData({ ...data, review: !data.review })} checked={data.review} />
                            <p className='font-semibold text-themeBlack-300 text-sm whitespace-nowrap'>{t('review_on_store')}</p>
                        </div>
                    </div>
                </div>}
            <div className='w-[55%] px-7 sm:w-full'>
                <Guide heading={t("Guide")} text={t("media_guide")} />
            </div>

            <div className='px-6 flex flex-col w-full overflow-hidden gap-5 mt-2'>
                <div className='flex justify-start items-start gap-2 mb-0 '>
                    <img src={data?.platform ? PLATFORMS.filter(p => p.key.toLocaleLowerCase() === data?.platform.toLocaleLowerCase())[0]?.logo : selectedMedia?.logo} className='w-8 h-8 object-cover' alt={selectedMedia.title} />
                    <p className='text-base font-semibold text-themeBlack-500 mt-1'>{selectedMedia.title}</p>
                </div>
                {/* Ad media fee */}
                {["Payback", "Review"].includes(data.campaign_option) &&
                    <div className='flex justify-between items-start gap-2 sm:gap-1'>
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={amounts.media_amount || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("amount")}</p>
                        </span>
                        <FaXmark className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                min="0"
                                name="creator_count"
                                value={data?.creator_count || 0}
                                readOnly={true}

                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Influencer")}</p>
                        </span>
                        <FaEquals className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={data.creator_count ? (amounts.media_amount * data.creator_count) : 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themePink bg-themePink/15 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("sub_total")}</p>
                        </span>
                    </div>}
                {/* delivery */}
                {data.campaign_type === 'Product' && data.campaign_option === "Review" &&
                    <div className='flex justify-between items-start gap-2 sm:gap-1'>
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={amounts.delivery_amount || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("delivery_Amount")}</p>
                        </span>
                        <FaXmark className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                min="0"
                                name="creator_count"
                                value={data?.creator_count || 0}
                                readOnly={true}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Influencer")}</p>
                        </span>
                        <FaEquals className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={(amounts.delivery_amount * data.creator_count) || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themePink bg-themePink/15 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("sub_total")}</p>
                        </span>
                    </div>}

                {/* payback */}
                {data.campaign_option === "Payback" && <>
                    <div className='flex justify-between items-start gap-2 sm:gap-1'>
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                name='payback_amount'
                                readOnly={true}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === '-' || e.key === 'e') e.preventDefault();
                                }}
                                value={data.payback_amount || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Payback")}</p>
                        </span>
                        <FaXmark className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                min="0"
                                value={data?.creator_count || 0}
                                readOnly
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Influencer")}</p>
                        </span>
                        <FaEquals className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={data.payback_amount && data.creator_count ? data.payback_amount * data.creator_count : 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themePink bg-themePink/15 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("sub_total")}</p>
                        </span>
                    </div>
                </>}

                {/* Subscription */}
                {data.campaign_option === "10%" &&
                    <div className='flex justify-between items-start gap-2 sm:gap-1'>
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                min="0"
                                name="tenPercent"
                                value={amounts.tenPercent || 0}
                                readOnly={true}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("amount")}</p>
                        </span>
                        <FaXmark className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={data?.creator_count || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Influencer")}</p>
                        </span>
                        <FaEquals className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={(data.creator_count * amounts?.tenPercent) || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themePink bg-themePink/15 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("sub_total")}</p>
                        </span>
                    </div>
                }
                {/* review */}
                {data.review && <>
                    <div className='flex justify-between items-start gap-2 sm:gap-1'>
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                name='review_on_store'
                                readOnly={true}
                                onChange={handleChange}
                                value={amounts.review_amount || 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("review_on_store")}</p>
                        </span>
                        <FaXmark className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                min="0"
                                value={data?.creator_count || 0}
                                readOnly
                                className='w-full text-center px-6 py-2 sm:px-1 border-themeGrey-100 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("Influencer")}</p>
                        </span>
                        <FaEquals className='text-themeBlack-300 text-2xl mt-2 sm:text-lg sm:mt-3' />
                        <span className='w-[28%] flex flex-col gap-1.5 justify-center items-center'>
                            <input
                                type='number'
                                readOnly
                                value={amounts.review_amount && data.creator_count ? amounts.review_amount * data.creator_count : 0}
                                className='w-full text-center px-6 py-2 sm:px-1 border-themePink bg-themePink/15 shadow-sm border-[1px] rounded-xl text-sm outline-none text-themeBlack-300'
                            />
                            <p className='text-sm text-themeBlack-300 text-center sm:text-xs whitespace-nowrap'>{t("sub_total")}</p>
                        </span>
                    </div>
                </>}

            </div>




           {data.campaign_option !== '10%' && <div className='flex flex-col gap-1 px-6 mt-2'>
                <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("region")}</h3>
                <p className='text-xs text text-themeBlack-300 sm:-mt-1'>{t('select_region')}</p>
                <div className='flex justify-start items-stretch gap-4 mt-2 sm:flex-wrap'>
                    <Tab
                        onChange={(e) => setData({ ...data, region: e.target.value })}
                        name="region"
                        value={data?.region || ""}
                        text={t("Korea")}
                        toValue={'Korea'}
                    />
                    <Tab
                        onChange={(e) => setData({ ...data, region: e.target.value })}
                        name="region"
                        value={data?.region || ""}
                        text={t("Japan")}
                        toValue={'Store'}
                        disabled={true}
                    />
                    <Tab
                        onChange={(e) => setData({ ...data, region: e.target.value })}
                        name="region"
                        value={data?.region || ""}
                        text={t("china")}
                        toValue={'China'}
                        disabled={true}
                    />
                    <Tab
                        onChange={(e) => setData({ ...data, region: e.target.value })}
                        name="region"
                        value={data?.region || ""}
                        text={t("USA")}
                        toValue={'USA'}
                        disabled={true}
                    />

                </div>
            </div>}

            {error && <p className='text-red-600 text-sm px-6'>{error}</p>}
            <div className='flex sm:hidden justify-between items-end px-6 pt-5 border-t-[1px] border-themeGrey-150 sm:fixed sm:bottom-0 sm:w-full sm:py-4 sm:bg-white'>
                <div className='w-1/2 sm:w-full'>
                    <p className='flex justify-between items-center text-sm text-themeBlack-300'>
                        <span className='font-medium'>{t('total')}</span>
                        <span className='font-medium'>{Number(total.total).toLocaleString()}</span>
                    </p>
                    <p className='flex justify-between items-center text-sm text-themeBlack-300'>
                        <span className='font-medium'>{t('vat')}</span>
                        <span className='font-medium'>{Number(total.vat).toLocaleString()}</span>
                    </p>
                    <p className='flex justify-between items-center text-sm text-themeBlack-300'>
                        <span className='font-bold'>{t('GrandTotal')}</span>
                        <span className='font-bold'>{Number(total.grandTotal).toLocaleString()}</span>
                    </p>
                </div>
                <div className='w-1/4 sm:w-1/3 sm:hidden'>
                    <Button active={data.paymentProccessed ? false : activeBtn} disabled={loading} onClick={onClick} text={t('save')} />
                </div>
            </div>
            <div className='hidden sm:flex sm:w-[130px] sm:p-3 sm:absolute sm:-top-[116px] sm:right-0'>
                <Button active={data.paymentProccessed ? false : activeBtn} disabled={loading} onClick={onClick} text={t('save')} />
            </div>
        </div>
    );
}

export default Media;

//
//
//