import React from 'react'

function Guide({ heading, text }) {
  return (
    <div className='flex rounded-xl px-4 py-2 bg-themeGrey-400 flex-col gap-0.5'>
      <h3 className='text-themeBlack-300 text-sm font-semibold sm:text-sm'>{heading}</h3>
      <p className='text-sm text-themeBlack-300 sm:text-sm'>
        {text.split('\n').map((line, index) => (
          <>
          <p key={index} className={index > 0 && 'mt-1.5'}>{line}</p> 
          </>
        ))}
      </p>
    </div>
  )
}

export default Guide
