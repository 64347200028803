import React, { useEffect, useState, useRef, useContext } from 'react'
import CategoryCarousel from './CategoryCarousel';
import { useTranslation } from 'react-i18next';
import { throttle } from 'lodash';
import { HiMiniBars3 } from "react-icons/hi2";
import { GoBellFill } from 'react-icons/go';
import { FaGlobe, FaUser, FaUserCircle } from 'react-icons/fa';
import Language from './Language';
import { Link, useLocation } from 'react-router-dom';
import { BsSearch } from 'react-icons/bs';
import { PLATFORMS } from '../constants';
import { PiChat, PiChatCircleDotsLight } from 'react-icons/pi';
import Notification from './Notification';
import { Context } from '../Provider/NotificationProvider';
import { Context as langContext } from '../Provider/LangaugeProvider';
import MobilePlatformSelection from './MobilePlatformSelection';
import { TfiBell } from "react-icons/tfi";
import { TbArrowsDownUp } from "react-icons/tb";
import RegionSelect from './RegionSelect';
import SortPopup from './SortPopup';
import { RxCross1 } from 'react-icons/rx';
import { IoNotificationsOutline } from "react-icons/io5";

function LandingNav({
    _scrolled = false,
    setQuery = () => { },
    query,
    regions
}) {
    const [active, setActive] = useState('Product');
    const [scrolled, setScrolled] = useState(false);
    const [t] = useTranslation("global");
    const [menuDrop, setMenuDrop] = useState(false);
    const location = useLocation()
    const [openLang, setOpenLang] = useState(false);
    const [regionSelect, setRegionSelect] = useState(false);
    const [selectPlatform, setSelectPlatform] = useState(false);
    const [searchSelect, setSearchSelect] = useState(false);
    const searchRef = useRef(null);
    const [notificationPopup, setNotificationPopuo] = React.useState(false)
    const { notifications } = useContext(Context)
    const [sort, setSort] = useState(false);
    const { siteLang } = useContext(langContext);
    const [platformValue, setPlatformValue] = useState(null)


    useEffect(() => {
        if (query.platform) {
            if (siteLang === 'kor') {
                setPlatformValue(PLATFORMS.find(p => p.key === query.platform).kor_title)
            }
            else {
                setPlatformValue(PLATFORMS.find(p => p.key === query.platform).title)
            }
        }
        else {
            setPlatformValue(null)
        }
    }, [siteLang, query])

    useEffect(() => {
        const handleFocus = () => setSearchSelect(true);
        const handleBlur = () => setSearchSelect(false);

        const inputElement = searchRef.current;

        if (inputElement) {
            inputElement.addEventListener('focus', handleFocus);
            inputElement.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputElement) {
                inputElement.removeEventListener('focus', handleFocus);
                inputElement.removeEventListener('blur', handleBlur);
            }
        };
    }, []);

    useEffect(() => {
        let throttleTimeout = null;
        const handleScroll = () => {
            if (throttleTimeout) return;

            throttleTimeout = setTimeout(() => {
                if (window.scrollY === 0) {
                    setScrolled(false);
                } else {
                    if (selectPlatform || regionSelect || searchSelect) {
                        setScrolled(false);
                    } else {
                        setScrolled(true);
                    }
                }

                throttleTimeout = null;
            }, 100)
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        setQuery(prev => ({ ...prev, type: active }));
    }, [active]);

    if (location.pathname.includes('/link')) return <></>;

    return (
        <>
            <div className='bg-white w-full sticky top-0 left-0 z-50'>
                {/* PC */}
                <>
                    <div className='sm:hidden flex px-16 pt-6 pb-7 justify-between items-start border-b-[1px] border-themeGrey-70'>
                        <span className='w-1/4 -mt-2.5'>
                            <Link to='/' className='w-12'>
                                <img src={'/logo.png'} className='w-9 h-9 object-contain' />
                            </Link>
                        </span>

                        {<div className={`flex relative flex-col gap-5 justify-start items-center ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled ? 'w-[40%]' : 'w-full'} px-20 transition-all ease-in duration-[130ms]`}>

                            <div className={`flex gap-2 ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled && '-mt-10 scale-0'} transition-all ease-in duration-75`}>
                                <button onClick={() => setActive("Product")} className={`outline-none ${active === 'Product' ? 'text-themePink !text-sm' : 'text-themeBlack-300'} font-normal px-2 text-sm`}>{t('Product')}</button>
                                <button onClick={() => setActive("Store")} className={`outline-none ${active === 'Store' ? 'text-themePink !text-sm' : 'text-themeBlack-300'} font-normal px-2 text-sm`}> {t('Store')}</button>
                                <button onClick={() => setActive("10%")} className={`outline-none ${active === '10%' ? 'text-themePink !text-sm' : 'text-themeBlack-300'} font-normal px-2 text-sm`}>{t('10%')}</button>
                            </div>

                            <div className={`${(regionSelect || selectPlatform || searchSelect) && '!bg-themeGrey-400'} flex relative border-[1px] border-themeGrey-150 overflow-hidden  w-full shadow-md ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled ? "h-[40px]" : "h-[50px]"} rounded-3xl`}>
                                {(query.type === 'Store' || query.type === '10%') && <div className={`rounded-full hover:bg-themeGrey-400 flex-1 ${regionSelect && 'bg-white shadow-lg hover:bg-white'} flex justify-start items-center cursor-pointer`}>
                                    <p onClick={() => setRegionSelect(true)} className='text-themeBlack-900 text-sm px-4 w-full h-full flex justify-start items-center  cursor-pointer capitalize'>{query?.region?.length > 1 ? query?.region?.replace('_', ' ') : scrolled ? t('anywhere') : t('searchinRegion')}</p>
                                    {regionSelect && <RegionSelect regions={regions} setRegionSelect={setRegionSelect} setQuery={setQuery} query={query} />}
                                </div>}
                                <div className={`rounded-full ${selectPlatform && '!bg-white shadow-lg hover:bg-white'} hover:bg-themeGrey-400 flex-1`}>
                                    <p onClick={() => setSelectPlatform(true)} className='text-themeBlack-900 text-sm px-4 w-full h-full flex justify-start items-center cursor-pointer capitalize'>{
                                        platformValue ?
                                            platformValue
                                            :
                                            scrolled ?
                                                t('platform')
                                                :
                                                t('SearchPlatform')}</p>
                                    {selectPlatform && <SelectPlatform query={query} setQuery={setQuery} setSelectPlatform={setSelectPlatform} />}
                                </div>
                                <div className={`${searchSelect && '!bg-white shadow-lg hover:bg-white'} rounded-full hover:bg-themeGrey-400 ${(query.type === 'Store' || query.type === '10%') ? 'w-[40%]' : 'w-[50%]'} `}>
                                    <input ref={searchRef} onChange={(e) => {
                                        const value = e.target.value;
                                        if (searchRef.current) {
                                            clearTimeout(searchRef.current);
                                        }
                                        searchRef.current = setTimeout(() => {
                                            setQuery({ ...query, search: e.target.value })
                                        }, 500);
                                    }} type="text" placeholder={scrolled ? t('searc') : t('search')} className='w-full h-full outline-none px-4 text-themeBlack-900 placeholder:text-themeBlack-900 bg-transparent text-sm' />
                                </div>
                                <span className={`absolute right-0 top-0 ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled ? "h-[30px] w-[30px]" : "h-[40px] w-[40px]"} rounded-full bg-themePink flex justify-center items-center m-1`}>
                                    <BsSearch className='text-white text-lg' />
                                </span>
                            </div>

                            {sort ? <div onClick={() => setSort(false)} className={`p-3.5 shadow-md border-[1px] border-themeGrey-150 rounded-full absolute right-2 cursor-pointer ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled ? 'scale-[0.8] bottom-0' : 'scale-100 bottom-0.5'}`}>
                                <RxCross1 className='text-themePink text-lg ' />
                            </div>
                                :
                                <div onClick={() => setSort(true)} className={`p-3.5 shadow-md border-[1px] border-themeGrey-150 rounded-full absolute right-2  cursor-pointer ${(!selectPlatform && !regionSelect && !searchSelect) && scrolled ? 'scale-[0.8] bottom-0' : 'scale-100 bottom-0.5'}`}>
                                    <TbArrowsDownUp className='text-themePink text-lg ' />
                                </div>}
                            {sort && <SortPopup setQuery={setQuery} query={query} setSort={setSort} scrolled={scrolled} />}
                        </div>}

                        <div className=' w-1/4 flex justify-end gap-4 -mt-2'>

                            {(!localStorage.getItem('token')) &&
                                <div onClick={() => {
                                    localStorage.setItem('loginRole', 'customer');
                                    window.location.pathname = '/login'
                                }} className='cursor-pointer flex justify-center whitespace-nowrap text-nowrap items-center px-4 gap-1 py-1.5 text-xs rounded-3xl border-[1px] border-themeGrey-150 shadow-sm'>
                                    {t('loginCustomer')}
                                </div>}
                            <>
                                <div className='flex justify-center items-center px-3 gap-2 py-1.5 rounded-3xl border-[1px] border-themeGrey-150 shadow-sm'>
                                    <FaGlobe onClick={() => setOpenLang(!regionSelect)} className='text-themeBlack-500 text-lg cursor-pointer hover:text-black' />
                                    {(localStorage.getItem('token')) ? <>
                                        <span onClick={() => { setNotificationPopuo(true) }} className='relative cursor-pointer'>
                                            {notifications.filter(n => n.read === false).length > 0 && <span className='absolute -top-3 -right-2 p-1 text-[8px] bg-themeBlue rounded-full text-white px-2'>{notifications.filter(n => n.read === false).length}</span>}
                                            <GoBellFill className='text-themeBlack-500 text-xl cursor-pointer hover:text-black' />
                                        </span>
                                        <FaUser onClick={() => setMenuDrop(true)} className='text-themeBlack-500 text-base cursor-pointer hover:text-black' />
                                    </>
                                        :
                                        <FaUserCircle onClick={() => {
                                            localStorage.setItem('loginRole', 'creator');
                                            window.location.pathname = '/login'
                                        }} className='text-themeBlack-500 text-xl cursor-pointer hover:text-black' />
                                    }
                                </div>

                                <Language open={openLang} setOpen={setOpenLang} />
                                {menuDrop && <MenuDrop setMenuDrop={setMenuDrop} />}
                            </>

                        </div>
                    </div>
                </>

                {/* Mobile */}
                <div className='hidden sm:flex px-6 py-1 pb-4 justify-between items-center gap-3 sm:-ml-1'>
                    <Link to='/' className='ml-[3px]'>
                        <img src={'/logo.png'} className='w-[50px] h-[50px] object-contain' />
                    </Link>
                    <div className='bg-themeGrey-400 rounded-lg border-[1px] border-themeGrey-70 px-2 py-2 flex justify-start items-center gap-2 w-full'>
                        <BsSearch className='text-themeGrey-300 text-lg' />
                        <input type='text' placeholder={t('search')} className='outline-none text-sm w-full text-themeGrey-300 bg-transparent' />
                    </div>
                    {(!localStorage.getItem('token') && !localStorage.getItem('user')) ?
                        <FaUserCircle onClick={() => {
                            localStorage.setItem('loginRole', 'creator');
                            window.location.pathname = '/login'
                        }} className='text-themeBlack-500 text-[46px] cursor-pointer hover:text-black' />
                        :
                        <>
                            <Link to={'/inbox'} className='relative cursor-pointer -mr-1.5'>
                                {notifications.filter(n => n.read === false).length > 0 && <span className='absolute top-0.5 right-1 p-1  text-[10px] bg-[#757575] rounded-full text-white'></span>}
                                <PiChatCircleDotsLight className='text-[#757575] text-3xl cursor-pointer opacity-65' />
                            </Link>
                            <span onClick={() => { setNotificationPopuo(true) }} className='relative cursor-pointer'>
                                {notifications.filter(n => n.read === false).length > 0 && <span className='absolute top-0.5 right-1.5 p-1  text-[10px] bg-[#757575] rounded-full text-white'></span>}
                                <IoNotificationsOutline className='text-[#757575] text-3xl cursor-pointer opacity-65' />
                            </span>
                            {notificationPopup && <span onClick={() => { setNotificationPopuo(false) }} className='absolute top-6 right-3 bg-transparent w-9 h-9'></span>}
                        </>
                    }
                </div>

                {notificationPopup && <Notification setNotificationPopuo={setNotificationPopuo} />}
                {location.pathname === '/' && <CategoryCarousel scrolled={scrolled} setQuery={setQuery} query={query} />}
            </div>
            {location.pathname === '/' && <MobilePlatformSelection scrolled={scrolled} setQuery={setQuery} query={query} />}
        </>
    )
}

export default LandingNav;

const MenuDrop = ({ setMenuDrop }) => {
    const menuRef = useRef(null);
    const [t] = useTranslation("global");
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuDrop(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setMenuDrop]);

    return (
        <div ref={menuRef} className='absolute glass top-12 overflow-hidden right-16 w-48 bg-white border-[1px] border-themeGrey-100 rounded-lg shadow-md z-50'>
            <ul className='w-full z-50 relative flex flex-col'>
                {user.role === 'customer' && <Link to='/manage' className='py-2 px-4 text-sm text-themeBlack-300 border-b-[1px] border-themeGrey-70 hover:!bg-white hover:text-black cursor-pointer'>{t("manage")}</Link>}
                {user.role === 'creator' && <Link to='/orders' className='py-2 px-4 text-sm text-themeBlack-300 border-b-[1px] border-themeGrey-70 hover:!bg-white hover:text-black cursor-pointer'>{t("orders")}</Link>}
                <Link to='/inbox' className='w-full py-2 px-4 text-sm text-themeBlack-300 border-b-[1px] border-themeGrey-70 hover:!bg-white hover:text-black cursor-pointer'>{t("inbox")}</Link>
                <Link to='/profile' className='w-full py-2 px-4 text-sm text-themeBlack-300 border-b-[1px] border-themeGrey-70 hover:!bg-white hover:text-black cursor-pointer'>{t("Profile")}</Link>
                <Link onClick={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    window.location.pathname = '/'
                }} className='py-2 px-4 text-sm text-themeBlack-300 hover:!bg-white hover:text-black cursor-pointer'>{t("logout")}</Link>
            </ul>
            <div className='absolute bottom-0 w-1/2 h-8 bg-themePink blur-3xl opacity-60 z-0' />
            <div className='absolute -top-2 right-0 w-1/2 h-5 bg-themePink blur-3xl opacity-30 z-0' />
        </div>
    )
}




const SelectPlatform = ({ setQuery, query, setSelectPlatform }) => {
    const menuRef = useRef(null);

    const { siteLang } = useContext(langContext);

    const platforms = [
        // {
        //     value: '',
        //     label: siteLang === 'kor'? "모두" : 'All',
        //     icon: '/logo.png'
        // },
        ...PLATFORMS.map(p => ({ value: p.key, label: siteLang === 'kor' ? p.kor_title : p.title, icon: p.logo }))
    ]

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setSelectPlatform(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    return (
        <div ref={menuRef} className='flex fixed w-[250px] top-28 bg-white z-50'>
            <div className={`absolute  right-0 top-0 w-full rounded-lg glass border-[1px] border-themeGrey-100 z-40 shadow-md overflow-hidden mb-10`}>
                <ul className='w-full h-full overflow-y-auto z-50 relative'>
                    {platforms.map((option, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                if (query?.platform === option.value) {
                                    setQuery((prev) => ({ ...prev, platform: '' }));
                                }
                                else {
                                    setQuery((prev) => ({ ...prev, platform: option.value }));
                                }
                                setSelectPlatform(false);
                            }}
                            className={`flex justify-between items-center py-2 px-4 gap-1 ${option?.value === query?.platform && 'bg-white'} hover:bg-white cursor-pointer text-sm border-b-[1px] border-themeGrey-100 text-themeBlack-300`}
                        >
                            <div className='flex justify-start items-center gap-2'>
                                {option.icon && <img src={option.icon} alt='icon' className='w-5 h-5 object-cover' />}
                                {option?.label}
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='absolute bottom-0 w-1/2 h-10 bg-themePink blur-3xl opacity-60 z-0' />
                <div className='absolute -top-2 right-0 w-1/2 h-10 bg-themePink blur-3xl opacity-50 z-0' />
            </div>
        </div>
    )
}