export const getCampaignStatus = (campaign, lang) => {
    switch (campaign.status) {
        case 'Paid':{
            const startDate = new Date(campaign.start_date);
            const yesterday = new Date();
            let daysleft = Math.floor((startDate - yesterday) / (1000 * 60 * 60 * 24));
            daysleft = daysleft <= 0 ? 1 : daysleft;
            return lang === 'kor' ? `모집 ${daysleft}일전` : `${daysleft} day left to start`
        }
        case 'Recruitment Period':
            return lang === 'kor' ? '모집중' : 'Recruit'
        case 'Approve Period':
            return lang === 'kor' ? '진행중' : 'Process'
        case 'Active Period':
            return lang === 'kor' ? '진행중' : 'Process'
        case 'Confirm Period':
            return lang === 'kor' ? '종료' : 'End'
        default:
            return lang === 'kor' ? '종료' : 'End'

    }
}