import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlCloudUpload } from "react-icons/sl";
import { convertBase64 } from '../../utils/convertBase64';
import InputGroup from '../InputGroup';
import { constraints } from '../../constants/validations';
import Button from '../Button';
import { RxCross2 } from "react-icons/rx";
import { imageUpload } from '../../services/ImageUpload';
import { createUpdateCampaign } from '../../services/createUpdateCampaign';
import { IMAGE_URL } from '../../constants';
import ProductImages from '../ProductImages';

function Product({ campaignData, setActive }) {
    const brandRef = useRef();
    const productRef = useRef();
    const [brandImage, setBrandImage] = useState(campaignData?.brand_image ? `${IMAGE_URL}/uploads/${campaignData?.brand_image}` : null);
    const [productImages, setProductImages] = useState(campaignData?.product_images?.length > 0 ? campaignData?.product_images.map(img => `${IMAGE_URL}/uploads/${img}`) : []);
    const [descTextCount, setDescTextCount] = useState(0);
    const [activeBtn, setActiveBtn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [removedImages, setRemovedImages] = useState([]);

    const [data, setData] = useState({
        productImage: [],
        brandImage: null,
        brand_title: campaignData?.brand_title ?? '',
        product_description: campaignData?.product_description ?? ''
    });

    const [t] = useTranslation("global");

    const handleBrandfile = async (e) => {
        const file = e.target.files[0];
        setData({ ...data, brandImage: file });
        const base64 = await convertBase64(file);
        setBrandImage(base64);
    };

    const handleProductImage = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const base64 = await convertBase64(file);
            setData((prevData) => ({
                ...prevData,
                productImage: [...prevData.productImage, file],
            }));
            setProductImages((prevImages) => [...prevImages, base64]);
        }
    };

    const onSubmit = async () => {
        setLoading(true);
        setError(null);

        try {
            let brand_image, product_images = [];

            if (data.brandImage) {
                brand_image = await imageUpload(data.brandImage);
                if (!brand_image) {
                    setError("Error Uploading Brand Image");
                    setLoading(false);
                    return;
                }
            }

            if (data.productImage.length > 0) {
                const uploadedImages = await imageUpload(data.productImage);
                if (!uploadedImages) {
                    setError("Error Uploading Product Images");
                    setLoading(false);
                    return;
                }
                product_images = Array.isArray(uploadedImages) ? uploadedImages : [];
            }

            // Filter out removed images from existing product images
            const remainingProductImages = campaignData.product_images
                ? campaignData.product_images.filter(img => !removedImages.includes(img))
                : [];

            const updatedProductImages = [...remainingProductImages, ...product_images];


            const response = await createUpdateCampaign({
                _id: campaignData._id,
                brand_image: brand_image ? brand_image[0] : campaignData?.brand_image,
                brand_title: data.brand_title,
                product_description: data.product_description,
                product_images: updatedProductImages,
            }, "Product");

            const res = await response.json();
            if (response.status === 200) {
                setActive(5)
            } else {
                setError(res.message);
            }

        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    const removeProductImage = async (img) => {
        setProductImages((prevImages) => prevImages.filter((_img) => _img !== img));

        const matchingFileIndex = await (async () => {
            for (let i = 0; i < data.productImage.length; i++) {
                const base64 = await convertBase64(data.productImage[i]);
                if (base64 === img) return i;
            }
            return -1;
        })();

        if (matchingFileIndex !== -1) {
            setData((prevData) => {
                const updatedProductImage = [...prevData.productImage];
                updatedProductImage.splice(matchingFileIndex, 1);
                return {
                    ...prevData,
                    productImage: updatedProductImage,
                };
            });
        }

        if (campaignData.product_images?.includes(img.replace(`${IMAGE_URL}/uploads/`, ''))) {
            setRemovedImages((prevRemoved) => [...prevRemoved, img.replace(`${IMAGE_URL}/uploads/`, '')]);
        }
    };


    useEffect(() => {
        if (
            (data.brandImage || campaignData?.brand_image) &&
            (data.productImage.length >= 1 || campaignData.product_images?.length >= 1) &&
            data.brand_title?.length >= 2 &&
            data.product_description?.length >= constraints.product_description.min
        ) {
            setActiveBtn(true);
        } else {
            setActiveBtn(false);
        }
    }, [data, campaignData]);

    return (
        <div className='flex flex-col gap-5 py-6 px-6 w-[100%] sm:pt-0 sm:pb-10 relative'>
            <div className='flex justify-start items-center sm:justify-center'>
                <div onClick={() => { if (!data.paymentProccessed) brandRef?.current?.click() }} className='relative overflow-hidden cursor-pointer flex justify-center items-center gap-1 flex-col w-[170px] h-[170px] rounded-full border-[1px] border-themeBlue border-dashed bg-themeBlue/10'>
                    {brandImage ?
                        <img src={brandImage} className='w-full h-full object-cover' alt='brandImage' />
                        :
                        <>
                            <SlCloudUpload className='text-themeBlue text-3xl' />
                            <p className='text-themeBlue text-center text-xs'>{t("upload_brandlgo")}</p>
                        </>
                    }
                    <input onChange={handleBrandfile} accept='image/*' ref={brandRef} type='file' className='absolute top-16 left-5 opacity-0' onClick={(e) => e.stopPropagation()} />
                </div>
            </div>
            <InputGroup
                solidLabel={t("brandName")}
                name='brand_name'
                type={"text"}
                flex='flex-col gap-2'
                placeholder={t("brandName_placeholder")}
                required={true}
                readOnly={campaignData.paymentProccessed}
                onChange={(e) => setData({ ...data, brand_title: e.target.value })}
                value={data.brand_title}
            />
            <InputGroup
                solidLabel={t("p_desc")}
                name='product_description'
                type={"textarea"}
                flex='flex-col gap-2'
                placeholder={t("p_desc_place")}
                required={true}
                textAreaSimple={true}
                readOnly={campaignData.paymentProccessed}
                onChange={(e) => { setData({ ...data, product_description: e.target.value }); setDescTextCount(e.target.value.length) }}
                value={data.product_description}
            >
                <p className="text-themePink text-xs">{descTextCount}/{constraints.product_description.max}</p>
            </InputGroup>
            <span className='flex flex-col gap-2 sm:gap-1'>
                <label className='text-base text-themeBlack-300 font-bold'>{t('product_desc_imgae')}</label>
                {/* <ProductImages removeProductImage={removeProductImage} productImages={productImages} data={data} setProductImages={setProductImages} setData={setData}/> */}

                <div className='flex flex-wrap justify-start gap-4'>
                    {productImages.map((img, index) => (
                        <div key={index} className='w-[48.5%]  sm:h-44 sm:w-full h-48 rounded-lg overflow-hidden relative shadow-sm border-[1px] border-themeGrey-150'>
                            <img src={img} alt='Product' className='w-full h-full object-cover' />
                            <span onClick={() => removeProductImage(img)} className='absolute top-1 right-1 bg-white shadow-lg px-3 py-2 rounded-lg text-xs flex justify-center items-center gap-1 cursor-pointer text-themePink'>
                                <RxCross2 className="text-themePink text-sm" />
                                {t("remove")}
                            </span>
                        </div>
                    ))}
                    {!data.paymentProccessed && <div onClick={() => productRef?.current?.click()} className='relative sm:h-44  sm:w-full overflow-hidden !cursor-pointer flex justify-center items-center gap-1 flex-col w-[48.5%] h-48 rounded-lg border-[1px] border-themeBlue border-dashed bg-themeBlue/10'>
                        <SlCloudUpload className='text-themeBlue text-3xl !cursor-pointer' />
                        <p className='text-themeBlue text-center text-xs !cursor-pointer'>{t("upload_p_img")}</p>
                        <input accept='image/*' onChange={handleProductImage} ref={productRef} type='file' className='absolute top-16 left-5 opacity-0' onClick={(e) => e.stopPropagation()} />
                    </div>}
                </div>
            </span>

            {error && <p className='text-red-600 text-sm'>{error}</p>}
            <div className='w-1/3 sm:-top-[116px] sm:p-3 sm:w-[130px] sm:right-0 sm:absolute'>
                <div className='w-full sm:px-0'>
                    <Button text={t("save")} active={activeBtn} disabled={loading} onClick={onSubmit} />
                </div>
            </div>
        </div>
    )
}

export default Product;
