import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { useTranslation } from 'react-i18next';
import { SlCloudUpload } from 'react-icons/sl';
import { RxCross1 } from 'react-icons/rx';
import { IMAGE_URL } from '../constants';
import { convertBase64 } from '../utils/convertBase64';
import { getCroppedImg } from '../utils/getCroppedImg'; // Assuming you have this utility

const DescriptionImage = ({ data, setData }) => {
    const [descriptionImage, setDescriptionImage] = useState(data.descriptionImage ? `${IMAGE_URL}/uploads/${data.descriptionImage}` : null);
    const productRef = React.createRef();
    const [t] = useTranslation('global');
    const [imageSrc, setImageSrc] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropping, setCropping] = useState(false);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
            setDescriptionImage(croppedImage);

            const base64Response = await fetch(croppedImage);
            const blob = await base64Response.blob();

            const fileName = 'cropped-image.jpg';
            const croppedImageFile = new File([blob], fileName, { type: 'image/jpeg' });

            setData((prevData) => ({
                ...prevData,
                descriptionImageToUpload: croppedImageFile,
            }));

            setCropping(false);
        } catch (e) {
            console.error(e);
        }
    }, [imageSrc, croppedAreaPixels]);

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;
        const base64 = await convertBase64(file);
        setImageSrc(base64);
        setCropping(true);
    };

    return (
        <div className='px-6 flex flex-col gap-2'>
            <h3 className='text-themeBlack-300 font-semibold text-lg'>{t("description_image")}</h3>
            <div className='flex flex-wrap justify-start gap-4'>
                {cropping ? (
                    <div className="absolute z-[50] w-[50%] h-96 sm:w-full sm:left-0 bg-black">
                        <Cropper
                            image={imageSrc}
                            crop={crop}
                            zoom={zoom}
                            aspect={1}
                            objectFit="contain"
                            onCropChange={setCrop}
                            onZoomChange={setZoom}
                            onCropComplete={onCropComplete}
                        />
                        <RxCross1 onClick={() => setCropping(false)} className='absolute cursor-pointer top-2 right-3 text-black bg-white rounded-full p-1 text-2xl z-20' />
                        <button className='absolute z-20 bg-themePink text-sm p-1.5 px-3 rounded-lg text-white bottom-2 right-2' onClick={showCroppedImage}>{t("crop")}</button>
                    </div>
                ) : descriptionImage ? (
                    <div onClick={() => productRef?.current?.click()} className='w-80 sm:min-h-48 sm:w-full h-56 rounded-lg overflow-hidden relative shadow-sm border-[1px] border-themeGrey-150'>
                        <img src={descriptionImage} alt='Product' className='w-full h-full object-cover' />
                    </div>
                ) : (
                    !data.paymentProccessed && (
                        <div onClick={() => productRef?.current?.click()} className='relative sm:min-h-48 sm:w-full overflow-hidden !cursor-pointer flex justify-center items-center gap-1 flex-col w-80 h-56 rounded-lg border-[1px] border-themeBlue border-dashed bg-themeBlue/10'>
                            <SlCloudUpload className='text-themeBlue text-3xl !cursor-pointer' />
                            <p className='text-themeBlue text-center text-xs !cursor-pointer'>{t("upload_p_img")}</p>
                        </div>
                    )
                )}
                <input accept='image/*' onChange={handleImageUpload} ref={productRef} type='file' className='absolute top-16 left-5 opacity-0' onClick={(e) => e.stopPropagation()} />
            </div>
        </div>
    );
};

export default DescriptionImage;
