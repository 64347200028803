import React, { useEffect, useState } from 'react';
import { constraints } from '../constants/validations';

const TagInput = ({ onChange, name, value, readOnly = false }) => {
    const [tags, setTags] = useState(value? value : []);
    const [inputValue, setInputValue] = useState('');
    const [ReadOnly, setReadOnly] = useState(readOnly)

    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ',') && inputValue.trim()) {
            if (!tags.includes(inputValue.trim())) {
                setTags([...tags, inputValue.trim()]);
                setInputValue('');
            }
        } else if (e.key === 'Backspace' && !inputValue) {
            removeTag(tags.length - 1);
        }
    };

    const handleBlur = () => {
        if (inputValue.trim() && !tags.includes(inputValue.trim())) {
            setTags([...tags, inputValue.trim()]);
            setInputValue(''); // Clear the input when focus is lost
        }
    };

    const removeTag = (index) => {
        setTags(tags.filter((tag, i) => i !== index));
    };


    useEffect(() => {
        if (window.location.pathname.startsWith('/profile') && tags.length === constraints.tags.profile) {
            setReadOnly(true)
        }
        else if (window.location.pathname.startsWith('/campaigns') && tags.length === constraints.tags.campaign) {
            setReadOnly(true)
        }
        else {
            setReadOnly(false)
        }
        onChange({
            target: {
                name,
                value: tags
            }
        })
    }, [tags])

    return (
        <div className="flex flex-wrap w-full cursor-text" onClick={() => document.getElementById(name)?.focus()}>
            <div className="flex flex-wrap gap-2 ">
                {tags.map((tag, index) => (
                    <div
                        key={index}
                        className="flex items-center px-1.5 bg-themeBlue/20 rounded-md text-themeBlack-300 h-8"
                    >
                        <span className="mr-3 text-sm leading-3 whitespace-nowrap text-nowrap">{name==="account_tag" ? '@': "#"}{tag}</span>
                        {!readOnly && <button
                            className="text-themeGrey-300 hover:text-themeBlack-900"
                            onClick={() => removeTag(index)}
                        >
                            &times;
                        </button>}
                    </div>
                ))}
                {!readOnly && <input
                    type="text"
                    value={inputValue}
                    readOnly={ReadOnly}
                    id={name}
                    onBlur={handleBlur}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className="flex-grow border-none outline-none resize-none text-themeGrey-150 text-sm min-w-[60px]"
                />}
            </div>
        </div>
    );
};

export default TagInput;
