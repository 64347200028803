import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoIosArrowForward } from 'react-icons/io'
import Basic from '../components/createCampaign/Basic'
import Mediia from '../components/createCampaign/Media'
import Product from '../components/createCampaign/Product'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchCampaign } from '../services/fetchCampaign'
import Loader from '../components/Loader'
import Detail from '../components/createCampaign/Detail'
import { FaArrowLeft } from 'react-icons/fa'
import Payment from '../components/createCampaign/Payment'

function CreateCampaign() {
    const [t] = useTranslation("global")
    const backfromPaymentPage = localStorage.getItem('fromPaymentPage') == 'true' ? true : false
    const [active, setActive] = useState(backfromPaymentPage? 5 : 1)
    const [campaignData, setCampaignData] = useState(null)
    const { _id } = useParams()
    const navigate = useNavigate()

    const [campaignId, setCampaignID] = useState(_id || localStorage.getItem('campaign_id'))


    useEffect(() => {
        setCampaignData(null)
        const getCamapign = async () => {
            const data = await fetchCampaign(campaignId || localStorage.getItem('campaign_id'))
            setCampaignData(data)
        }
        getCamapign()

        return () => {
            localStorage.removeItem('campaign_id')
            localStorage.removeItem('fromPaymentPage')
        }
    }, [campaignId, active, campaignId])



    return (
        <div className='flex flex-col justify-start items-start'>
            <div className='flex justify-between items-end gap-1 mt-20 w-full sm:px-6 sm:m-0 sm:flex-col sm:justify-start sm:items-start sm:gap-7'>
                <div className='hidden sm:flex justify-start items-center gap-5 py-0 pt-5 '>
                    <FaArrowLeft onClick={() => navigate('/manage')} className='bg-themePink text-[28px] px-2 py-1 text-white rounded-full' />
                    <h1 className='text-black text-lg font-bold'>{t('createCompaign')}</h1>
                    {active > 1 && campaignData && <button onClick={() => setActive(active-1)} className='hidden sm:flex rounded-lg bg-themeGrey-50/50 text-white font-bold absolute right-[125px] py-3 px-2.5 outline-none w-[104px] justify-center text-sm'>
                        Back
                    </button>}
                </div>
                <h2 className='text-lg leading-4 font-semibold text-themePink sm:hidden'>{_id ? t('editCampaign') : t("createCompaign")}</h2>
                <div className='flex justify-end items-center sm:justify-between sm:w-full'>
                    <button onClick={() => setActive(1)} className={`text-sm ${active > 0 && active >= 1 ? 'text-themePink' : 'text-themeBlack-500'} font-medium py-0 px-2 whitespace-nowrap sm:pl-0 sm:pr-1 outline-none`}>
                        {t("Basicinfo")}
                    </button>
                    <IoIosArrowForward className={active > 1 && active >= 2 ? 'text-themePink' : 'text-themeBlack-500'} />
                    <button disabled={campaignData?.campaign_method ? false : true} onClick={() => setActive(2)} className={`text-sm ${active > 1 && active >= 2 ? 'text-themePink' : 'text-themeBlack-500'} font-medium py-0 px-2 whitespace-nowrap sm:pr-0 sm:px-1 outline-none`}>
                        {t("Media")}
                    </button>
                    <IoIosArrowForward className={active > 2 && active >= 3 ? 'text-themePink' : 'text-themeBlack-500'} />
                    <button disabled={campaignData?.creatorLevel ? false : true} onClick={() => setActive(3)} className={`text-sm ${active > 2 && active >= 3 ? 'text-themePink' : 'text-themeBlack-500'} font-medium py-0 px-2 whitespace-nowrap sm:px-1 outline-none`}>
                        {t("Detail")}
                    </button>
                    <IoIosArrowForward className={active > 3 && active >= 4 ? 'text-themePink' : 'text-themeBlack-500'} />
                    <button disabled={campaignData?.content_deadline ? false : true} onClick={() => setActive(4)} className={`text-sm ${active > 3 && active >= 4 ? 'text-themePink' : 'text-themeGrey-300'} font-medium py-0 px-2 whitespace-nowrap sm:px-1 outline-none`}>
                        {t("Product")}
                    </button>
                    <IoIosArrowForward className={active > 4 && active >= 5 ? 'text-themePink' : 'text-themeBlack-500'} />
                    <button disabled={campaignData?.product_images?.length > 0 ? false : true} onClick={() => setActive(5)} className={`text-sm ${active > 4 && active >= 5 ? 'text-themePink' : 'text-themeGrey-300'} font-medium py-0 px-2 whitespace-nowrap sm:px-1 outline-none`}>
                        {t("payment")}
                    </button>
                </div>
            </div>
            <hr className='w-full h-1 border-t-0 my-3 border-b-[2px] border-themeGrey-70 shadow-sm sm:shadow-none sm:mt-0' />
            {campaignId && !campaignData ? <Loader />
                :
                <div className='bg-white rounded-xl border-[1px] border-themeGrey-500 w-[75%] sm:w-full sm:border-none sm:mt-1'>
                    {active === 1 && <Basic campaignData={campaignData} setActive={setActive} setCampaignID={setCampaignID} />}
                    {active === 2 && <Mediia campaignData={campaignData} setActive={setActive} />}
                    {active === 3 && <Detail campaignData={campaignData} setActive={setActive} />}
                    {active === 4 && <Product campaignData={campaignData} setActive={setActive} />}
                    {active === 5 && <Payment campaignData={campaignData} setActive={setActive} />}
                </div>
            }
        </div>
    )
}

export default CreateCampaign
