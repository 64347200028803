import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

function Keyword({
    value = [],
    onChange = () => { },
    placeholder = "",
    name = "",
    readOnly = false
}) {
    const [keywords, setKeywords] = useState(value ? value : []);
    const [inputValue, setInputValue] = useState('');
    const [t] = useTranslation("global");

    const handleKeyDown = (e) => {
        if ((e.key === 'Enter' || e.key === ',') && inputValue.trim()) {
            if (!keywords.includes(inputValue.trim())) {
                setKeywords([...keywords, inputValue.trim()]);
                setInputValue('');
            }
        }
    };

    const handleBlur = () => {
        if (inputValue.trim() && !keywords.includes(inputValue.trim())) {
            if (!keywords.includes(inputValue.trim())) {
                setKeywords([...keywords, inputValue.trim()]);
                setInputValue('');
            }
        }
    };

    const removeKeyword = (index) => {
        setKeywords(keywords.filter((tag, i) => i !== index));
    };

    useEffect(() => {
        onChange({
            target: {
                name,
                value: keywords
            }
        })
    }, [keywords])
    return (
        <div className='flex gap-2 items-end sm:flex-wrap'>
            {keywords.map((keyword, index) => (
                <div
                    key={index}
                    className="relative w-1/3 flex flex-col justify-end gap-1 sm:w-full"
                >
                    {!readOnly && <p onClick={() => removeKeyword(index)} className='text-xs cursor-pointer self-end text-themePink'>{t('remove')}</p>}
                    <input
                        type='text'
                        value={keyword}
                        readOnly={true}
                        className='py-2.5 px-4 border-themeGrey-100 shadow-sm border-[1px] rounded-xl outline-none bg-transparent transition-all ease-in duration-100 w-full text-sm text-themeBlack-300 placeholder:text-themeGrey-150'
                    />
                </div>
            ))}
            {keywords.length < 3 && !readOnly &&
                <input
                    type='text'
                    value={inputValue}
                    placeholder={placeholder}
                    readOnly={false}
                    onBlur={handleBlur}
                    onChange={(e) => setInputValue(e.target.value)}
                    onKeyDown={handleKeyDown}
                    className='py-2.5 w-1/3 px-4 border-themeGrey-100 sm:w-full shadow-sm border-[1px] rounded-xl outline-none bg-transparent transition-all ease-in duration-100 text-sm text-themeBlack-300 placeholder:text-themeGrey-150'
                />}
        </div>
    )
}

export default Keyword
