import React, { useContext, useEffect, useRef, useState } from 'react'
import LayoutHeader from '../components/LayoutHeader'
import { useTranslation } from 'react-i18next'
import { Link, Navigate, NavLink, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import Input from '../components/Input'
import { defaultCP } from '../utils/defaultCP'
import { getMyCampaigns } from '../services/getMyCampaigns'
import Loader from '../components/Loader'
import { IMAGE_URL, PLATFORMS } from '../constants'
import { convertToSeoulTime } from '../utils/convertToSeoulTime'
import CampaignMenu from '../components/CampaignMenu'
import { IoIosArrowDown, IoMdArrowDropdown } from 'react-icons/io'
import Select from '../components/Select'
import { RiFileCopyFill } from 'react-icons/ri'
import { duplicateCampaign } from '../services/duplicateCampaign'
import { formateDate } from '../utils/formateDate'
import { portoneRefundApi } from '../utils/portone'
import { cancelCampaign } from '../services/cancelCampaign'
import CampaignSchedule from '../components/CampaignSchedule'
import CampaignStatus from '../components/CampaignStatus'
import { Context } from '../Provider/LangaugeProvider'

function MyCampaigns() {
  const [t] = useTranslation("global")
  const debounceTimeout = useRef(null);
  const [searchText, setSearchText] = useState('')
  const [status, setStatus] = useState('all')
  const [sort, setSort] = useState('New')
  const { sitelang } = useContext(Context)

  const [campaigns, setCampaigns] = useState(null)

  const getCampaigns = async () => {
    const data = await getMyCampaigns(status, searchText, sort)
    setCampaigns(data)
  }

  useEffect(() => {
    setCampaigns(null)
    getCampaigns()
  }, [searchText, status, sort])

  const handleSearchChange = (e) => {
    const value = e.target.value;
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      setSearchText(value);
    }, 500);
  }


  return (
    <div className='sm:px-6 sm:pb-24'>

      <div className='hidden sm:flex justify-between items-center gap-8 mb-4 py-0 pt-5'>
        <h1 className='text-black flex-1 text-lg font-bold'></h1>
        <h1 className='text-black flex-1 text-lg font-bold text-nowrap'>{t('my_campaign')}</h1>
        <Link to={'/campaigns/create'} className='text-black text-center flex-1 text-nowrap text-sm px-2 py-1 border-[1px] rounded-lg border-themeGrey-70'>{t('create')}</Link>
      </div>

      <div className='flex justify-between items-end sm:flex-col'>
        <LayoutHeader title={t("my_campaign")} />
        <span className='w-1/2 scale-y-[0.8] sm:w-full sm:scale-y-[0.9] sm:flex hidden bg-themeGrey-70/50 rounded-xl overflow-hidden'>
          <Input
            type={'text'}
            placeholder={t('search_by_title')}
            value={searchText}
            onChange={handleSearchChange}
          />

        </span>
        <div className='flex justify-end sm:justify-between sm:mt-2 items-center gap-3 w-[70%] sm:w-full'>
          <div className='flex justify-center items-center gap-3 -mb-3 mr-3'>
            <span className='flex justify-center items-center gap-1.5'>
              <input type='checkbox' className='checkbox' name='status' checked={status === 'all'} onChange={() => setStatus('all')} />
              <p className='text-xs text-themeGrey-300 whitespace-nowrap'>{t('all')}</p>
            </span>
            <span className='flex justify-center items-center gap-1.5'>
              <input type='checkbox' className='checkbox' name='status' checked={status === 'Active'} onChange={() => setStatus('Active')} />
              <p className='text-xs text-themeGrey-300 whitespace-nowrap'>{t('Active')}</p>
            </span>
          </div>

          <span className='w-1/2 scale-y-[0.8] sm:w-full sm:scale-100 sm:hidden'>
            <Input
              type={'text'}
              placeholder={t('search_by_title')}
              value={searchText}
              onChange={handleSearchChange}
            />

          </span>
          <span className='scale-y-[0.8] w-1/4 sm:scale-90 sm:w-1/2'>
            <Select
              options={[{ label: sitelang === 'kor'? '최신순': 'New', value: 'New' }, { label: sitelang === 'kor'? '금액순': 'High', value: 'High' }]}
              onChange={(e) => setSort(e.target.value)}
              value={sort}
              simple={true}
            />

          </span>
        </div>

      </div>
      <hr className='w-full h-1 border-t-0 my-2 border-b-[2px] border-themeGrey-70 shadow-sm sm:hidden' />

      <div className='flex flex-col gap-4 sm:mt-2'>
        <div className='flex flex-col gap-4 w-[60%] sm:w-full'>
          {!campaigns && <Loader />}
          {campaigns?.length > 0 &&
            <>
              {campaigns?.map(campaign => (
                <Campaign campaign={campaign} key={campaign._id} getCampaigns={getCampaigns} path='incomplete' />
              ))}
            </>
          }
          {campaigns?.length === 0 && <p className='text-themeGrey-300 text-sm'>{t("no_activity")}</p>}
        </div>

      </div>
    </div>
  )
}

export default MyCampaigns



const Campaign = ({ campaign, getCampaigns, path }) => {
  const navigate = useNavigate()
  const [t] = useTranslation("global")
  const [scheduleExpanded, setScheduleExpanded] = useState(false)
  const [statusExpanded, setStatusExpanded] = useState(false)



  const _duplicateCampaign = async () => {
    await duplicateCampaign(campaign._id);
    getCampaigns()
  }

  const _cancelCampaign = async () => {
    const confirm = window.confirm(t('cancel_confirm'));
    if (confirm) {
      const refunded = await portoneRefundApi({
        paymentID: campaign.paymentId,
        refundAmount: campaign.paidAmount,
        totalPayment: campaign.paidAmount
      })
      if (refunded) {
        await cancelCampaign(campaign._id, campaign.paidAmount, campaign.paymentId);
        getCampaigns()
      }
    }
  }


  return (  
    <div className='cursor-pointer bg-white rounded-lg flex justify-between flex-col px-4 sm:p-2 py-4 border-[1px] sm:shadow-sm border-themeGrey-70 shadow-sm'>
      <div className='flex justify-start items-stretch gap-4 w-full sm:w-full sm:gap-3 overflow-hidden'>
        <div className='flex flex-col gap-1 w-28 sm:w-[160px]'>
          <img src={campaign.descriptionImage?.length > 0 ? `${IMAGE_URL}/uploads/${campaign.descriptionImage}` : defaultCP()} className='w-full min-h-24 sm:h-28 object-cover rounded-md' />
          <p onClick={_duplicateCampaign} className='text-xs border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeBlack-300 pl-3 pr-4 py-1.5 flex gap-2 justify-center'> {t('duplicate')}</p>
        </div>
        <div className='py-1 flex justify-start items-start flex-col w-full'>

          <h2 className='text-[#3B3B3B] font-semibold text-base'>{campaign.campaign_title}</h2>
          <p className='text-themeBlack-500 font-normal text-xs'>{`${t('startDate')}: ${formateDate(campaign.start_date)}`} </p>
          <div className='flex justify-start gap-1 flex-wrap'>
            <p className='text-xs sm:px-2 border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeBlack-300 pl-3 pr-4 py-1.5 flex gap-2 items-center'>{campaign.campaign_type}</p>
            <p className='text-xs sm:px-2 border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeBlack-300 pl-3 pr-4 py-1.5 flex gap-2 items-center'>{campaign.category?.name}</p>
            {PLATFORMS.find(p => p.key === campaign.platform)?.title?.length > 0 && <p className='text-xs sm:px-2 border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeBlack-300 pl-3 pr-4 py-1.5 flex gap-2 items-center'>{PLATFORMS.find(p => p.key === campaign.platform)?.title}</p>}
            <p className='text-xs sm:px-2 border-[1px] border-themeGrey-70 mt-1 rounded-md text-themeBlack-300 pl-3 pr-4 py-1.5 flex gap-2 items-center'>{campaign.campaign_option}</p>
          </div>
          <div className='flex justify-between w-full items-center  mt-2'>
            <span>
              <p className='text-themeBlack-500 font-normal text-xs'>ID: {campaign.campaign_id}</p>
            </span>
            <p className='text-themeBlack-500 font-normal text-xs'>{campaign.media_fee?.toLocaleString()} {t('Won')}</p>
          </div>
        </div>
      </div>
      <hr className='flex w-full h-0.5 border-b-[1px] border-themeGrey-70 border-t-0 my-2' />
      <div className='w-full flex justify-between items-start relative sm:w-full sm:p-0'>

        <div className='flex justify-start items-center'>
            <p onClick={() => {setStatusExpanded(!statusExpanded); setScheduleExpanded(false) }} className='flex justify-center items-center gap-1 px-2 py-1 text-sm text-themeBlack-300'>
                {t('status')}
                <IoMdArrowDropdown className={`transform ${statusExpanded ? 'rotate-180' : 'rotate-0'} transition-all duration-100 ease-in text-xl`} />
            </p>
            <p onClick={() => {setScheduleExpanded(!scheduleExpanded); setStatusExpanded(false) }} className='flex justify-center items-center gap-1 px-2 py-1 text-sm text-themeBlack-300'>
                {t('schedule')}
                <IoMdArrowDropdown className={`transform ${scheduleExpanded ? 'rotate-180' : 'rotate-0'} transition-all duration-100 ease-in text-xl`} />
            </p>
        </div>

        <div className='flex justify-between items-center'>
          <>
            {['Paid', 'Recruitment Period'].includes(campaign.status) ?
              <button onClick={_cancelCampaign} className='px-4 py-1 font-semibold text-sm text-white border-[1px] rounded-md bg-themePink'>
                {t('cancel')}
              </button>
              :
              <></>
            }
          </>
          <>
            {campaign.status === 'Incomplete' ?
              <Link to={`/campaigns/edit/${campaign._id}`} className='px-4 py-1 font-semibold text-sm text-white border-[1px] rounded-md bg-themePink'>
                {t('edit')}
              </Link>
              :
              !['Paid', 'Incomplete', 'Cancelled'].includes(campaign.status) ?
                <Link to={`/manage/${campaign.campaign_title}/${campaign._id}`} className='px-4 py-1 font-semibold text-sm text-white border-[1px] rounded-md bg-themePink'>
                  {t('manage')}
                </Link>
                :
                <></>
            }
          </>

        </div>
      </div>
      
      <CampaignSchedule campaign={campaign} scheduleExpanded={scheduleExpanded} />
      <CampaignStatus campaign={campaign} statusExpanded={statusExpanded} />

    </div>
  )
}